import landingImage from "../../../Assets/Feuerbestattungen_landing_img.jpg";
import LandingImageHeader from "../../../Components/LandingImageHeader/LandingImageHeader";
import "./Feuerbestattungen.scss";
import SideNav from "../../../Components/SideNav/SideNav";
import PriceModel from "../../../Components/PriceModel/PriceModel";
import { Helmet } from 'react-helmet-async';
import { contentData } from "./content-Feuerbestattungen";

export default function Feuerbestattungen() {
  return (
    <div className="flex-column feuerbestattungen-p page">
      <Helmet>
        <title>Feuerbestattung | Bestattungen Lichtblick</title>
        <meta name='description' content='Mit einer Erdbestattung Abschied auf eine würdevolle Weise nehmen. Jetzt Angebot anfordern!' />
      </Helmet>

      <LandingImageHeader
        img={landingImage}
        alt={"Drei brennende Kerzen auf dunklem Hintergrund."}
        text={`Die Feuerbestattung – eine moderne und würdevolle Alternative zur klassischen Bestattung.`}
      />
      <div className="flex-div flex-row">
        <SideNav />
        <div
          tabIndex={0}
          id="page-content"
          className="page-content flex-column"
        >
          <div className="price-block ">
            <article className="margin-div flex-column">
              <h3>{contentData.h3_2}</h3>
              <div className="content flex-column">
                <PriceModel
                  heading="Feuerbestattung mit Traufeier"
                  price="999"
                  services="zzgl. städt. Kosten"
                  hasBtn={false}
                />
                <p className="p1">Unsere Feuerbestattungsdienstleistungen bieten individuelle Gestaltungsmöglichkeiten für einen persönlichen Abschiedsritus. Von der Auswahl des Krematoriums bis zur Ausgestaltung der Gedenkfeier unterstützt Sie Bestattungen Lichtblick bei jedem Schritt. Erfahrene Berater stehen Ihnen zur Seite, um alle organisatorischen Aspekte zu klären und den Prozess so reibungslos wie möglich zu gestalten. Eine Betreuung und Rücksicht auf persönliche Wünsche schafft eine einfühlsame Atmosphäre. Vertrauen Sie auf unsere Erfahrungen, um eine Feuerbestattung zu arrangieren, die den individuellen Vorstellungen und dem Gedenken an den Verstorbenen gerecht wird. Kontaktieren Sie uns für eine professionelle Unterstützung.</p>
              </div>
            </article>
          </div>
          <div className="details-ul flex-column">
            <h3>{contentData.h3_3}</h3>
            <div className="bullet-point ">
              <ul>
                {contentData.ul1.map((el, ind) => {
                  return <li key={ind}>{el}</li>;
                })}
              </ul>
              <ul>
                {contentData.ul2.map((el, ind) => {
                  return <li key={ind}>{el}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
