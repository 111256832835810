import "./App.scss";
import NavbarComp from "./Components/Navbar/Navbar";
import { useContext, useEffect } from "react";
import { AccessibilityContext } from "./context/accessibilityContext";
import Footer from "./Components/Footer/Footer";
import RenderRoutes from "./Pages/router";
import AccessibilityMenu from "./Components/AccessibilityMenu/AccessibilityMenu";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const { fontSizeMag } = useContext(AccessibilityContext);
    const helmetContext = {};
  useEffect(() => {
    document.documentElement.style.fontSize = `${fontSizeMag}px`;
  }, [fontSizeMag]);
  return (
      <HelmetProvider context={helmetContext}>
          <div className="App">
              <NavbarComp />
              <AccessibilityMenu />
              <RenderRoutes />
              <Footer />
          </div>
      </HelmetProvider>
  );
}

export default App;
